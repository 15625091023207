


























import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProductReviewPropsSimple } from './ProductReview.contracts'
import VerifiedIcon from './partials/VerifiedIcon.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductReviewSimple>({
  name: 'ProductReviewSimple',
  components: { VerifiedIcon }
})
export class ProductReviewSimple extends Vue implements ProductReviewPropsSimple {
  @Prop({ type: String, required: true })
  public readonly author!: ProductReviewPropsSimple['author']

  @Prop({ type: String, required: true })
  public readonly content!: ProductReviewPropsSimple['content']

  @Prop({ type: String, required: true })
  public readonly createdAt!: ProductReviewPropsSimple['createdAt']

  @Prop({ type: Number, required: true })
  public readonly rate!: ProductReviewPropsSimple['rate']

  public get formatterCreatedAt () {
    const createdAtDateTime = new Date(this.createdAt).getTime()
    const currentDate = new Date()
    const todayMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime()
    const ONE_DAY = 24 * 60 * 60 * 1000

    if (createdAtDateTime > todayMidnight) {
      return this.$t('front.products.productReview.simple.today')
    } else if (createdAtDateTime > todayMidnight - ONE_DAY) {
      return this.$t('front.products.productReview.simple.yesterday')
    } else if (createdAtDateTime > todayMidnight - 2 * ONE_DAY) {
      return this.$t('front.products.productReview.simple.dayBeforeYesterday')
    } else {
      return `${this.createdAt.slice(8, 10)}.${this.createdAt.slice(5, 7)}.${this.createdAt.slice(0, 4)}`
    }
  }
}

export default ProductReviewSimple
