








































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { ProductData } from '../../../../contexts'

import { Average } from './RateSummary.contracts'

@Component<RateSummary>({
  name: 'RateSummary'
})
export class RateSummary extends Vue {
  @Prop({ type: Array, required: false })
  public amountPerRate?: []

  @Prop({ type: Object, required: false })
  public average?: Average

  @Prop({ required: true })
  public readonly product!: ProductData | null

  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  public get sumReviews (): number | null {
    if (typeof this.amountPerRate === 'undefined') {
      return null
    }

    return this.amountPerRate?.reduce<number>((previousValue, currentValue) => {
      return previousValue + currentValue
    }, 0)
  }

  public get stars (): number[] {
    return Array.from(Array(6).keys()).map((star) => (
      star
    )).reverse()
  }
}

export default RateSummary
