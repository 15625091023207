



























import { Component, Mixins } from 'vue-property-decorator'

import { Inject, logger } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { Loader } from '../../../../front/shared/molecules/Loader'
import ProductReviewSimple from '../../../../front/products/molecules/ProductReview/ProductReviewSimple.vue'
import RateSummaryEnhanced from '../../../../front/products/molecules/RateSummary/RateSummaryEnhanced.vue'

import { ProductReviewsMixin } from '../../ProductReviews.mixin'
import {
  ITrustedShop,
  TrustedShopRepositoryType
} from '../../../../front/shared/repositories/trustedShop'
import { ProductReviewPropsSimple } from '../../../../front/products/molecules/ProductReview'
import { TrustedShopReviewsMixin } from '../../../../front/shared/mixins/trustedShopReviewsMixin'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<LoadMore>({
  name: 'LoadMore',
  components: { Loader, ProductReviewSimple, RateSummaryEnhanced },
  async created (): Promise<void> {
    if (this.useVendorReviews) {
      await this.loadReviewsCount()
      this.initShownReviews()
    }
  },
  mounted () {
    this.eventBus.handle('product:trustedShop-global-loading', (event: EventPayload<boolean>) => {
      if (event.payload) {
        this.isLoading = true
        this.initShownReviews()
      } else {
        this.isLoading = false
      }
    })
  }
})
export class LoadMore extends Mixins(ProductReviewsMixin, TrustedShopReviewsMixin) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Inject(TrustedShopRepositoryType)
  protected readonly trustedShopRepository?: ITrustedShop

  public skus: string[] = []
  public SHOWN_REVIEWS: number = 0
  public shouldLoadMore: boolean = true
  public reviewsData: Array<ProductReviewPropsSimple> | null = null

  /**
   * Determines whether to use review from external vendor
   */
  public get useVendorReviews (): boolean {
    return this.config.useVendorReviews
  }

  /**
   * Handles 'load more' action
   */
  public loadMoreReviews (): void {
    this.SHOWN_REVIEWS += Number(this.content.reviewsPerPage) - 1

    if (this.SHOWN_REVIEWS >= this.reviewsData!.length) {
      this.SHOWN_REVIEWS = this.reviewsData!.length
      this.shouldLoadMore = false
    }
  }

  /**
   * Loads TrustedShop product reviews count
   * @protected
   */
  protected async loadReviewsCount (): Promise<void> {
    if (!this.content.product) {
      return
    }

    for (const variant of Object.values(this.content.product.variants)) {
      this.skus.push(variant.sku)
    }

    if (this.skus.length === 0) {
      return
    }

    try {
      this.isLoading = true

      await this.loadReviewsTotalCount(this.skus)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Initializes a number of reviews that can be shown at first
   * @protected
   */
  protected initShownReviews (): void {
    this.reviewsData = this.$store.getters['products/getProductReviewsData']

    if (!this.reviewsData || this.reviewsData.length === 0) {
      return
    }

    if (Number(this.content.reviewsPerPage) > this.reviewsData.length) {
      this.SHOWN_REVIEWS = this.reviewsData.length
      this.shouldLoadMore = false
    } else {
      this.SHOWN_REVIEWS = Number(this.content.reviewsPerPage)
    }
  }
}

export default LoadMore
