

















































import { Component, Prop } from 'vue-property-decorator'

import { RateSummaryProps } from './RateSummary.contracts'
import { StructureConfigurable } from '../../../../support/mixins'
import { RATE_SUMMARY_CONFIG_MAP, RATE_SUMMARY_KEY, RateSummaryConfig } from './RateSummary.config'
import { toImageProps } from '../../../shared/support'

@Component<RateSummaryEnhanced>({
  name: 'RateSummaryEnhanced',
  created () {
    this.config = this.getComponentConfig(RATE_SUMMARY_KEY, { ...RATE_SUMMARY_CONFIG_MAP })
  }
})
export class RateSummaryEnhanced extends StructureConfigurable {
  @Prop({ type: Number, required: true })
  public readonly totalReviewsCount!: number

  public toImageProps = toImageProps

  protected config!: RateSummaryConfig

  public get omnibusDetailsUrl (): string | null {
    return this.getConfigProperty('omnibusDetailsUrl')
  }

  public get rateSummary (): RateSummaryProps {
    return {
      ...this.$store.getters['products/getRateSummary'],
      summaryImage: {
        src: require('./assets/trusted-shop-black.png'),
        alt: ''
      }
    }
  }

  public get totalAmount () {
    let total = 0
    for (const value of this.rateSummary.amountPerRate) {
      total = total + value
    }
    return total
  }

  public get maxLabels () {
    return `${this.rateSummary.amountPerRate.length}.00`
  }
}

export default RateSummaryEnhanced
