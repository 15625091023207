/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const RATE_SUMMARY_KEY = 'RateSummaryComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface RateSummaryConfig {
  omnibusDetailsUrl: string | null
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const RATE_SUMMARY_CONFIG_MAP: RateSummaryConfig = {
  omnibusDetailsUrl: null
}
